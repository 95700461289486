<template>
  <!-- Begin Feature Area -->
  <div id="feature" class="feature-area feature-style-2">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-area section-style-3">
            <div class="section-wrap">
              <h2 class="section-title">Features</h2>
              <p class="section-desc">
                Some of the solutions PHIX.FIT offers in the platform.
              </p>
            </div>
            <!--                        <div class="btn-wrap">
                            <router-link class="btn btn-custom-size paua-color blackcurrant-hover" to="/contact">Other Features</router-link>
                        </div>-->
          </div>
          <swiper
            class="swiper-container feature-slider-2"
            :breakpoints="swiperOptions.breakpoints"
            :slides-per-view="2"
            :spaceBetween="30"
            :loop="false"
            :effect="fade"
            :pagination="swiperOptions.pagination"
            @swiper="onSwiper"
          >
            <swiper-slide
              v-for="(slider, sliders) in sliders"
              :key="sliders"
              class="feature-item"
            >
              <div class="feature-icon">
                <img :src="`${slider.imgSrc}`" :alt="slider.alt" />
              </div>
              <div class="feature-content">
                <h3 class="title">{{ slider.slidersubTitle }}</h3>
                <p class="desc" v-html="slider.sliderDesc"></p>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
  <!-- Features Area End Here -->
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination } from "swiper";
SwiperCore.use([Pagination]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: 1,
            slidesPerColumn: 1,
            slidesPerGroup: 1,
            slidesPerColumnFill: "row",
          },
          576: {
            slidesPerView: 1,
            slidesPerColumn: 2,
            slidesPerGroup: 1,
            slidesPerColumnFill: "row",
          },
          992: {
            slidesPerView: 2,
            slidesPerColumn: 2,
            slidesPerGroup: 1,
            slidesPerColumnFill: "row",
          },
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "bullets",
        },
      },
      sliders: [
        {
          imgSrc: "images/feature/icon/1-1-110x110.png",
          alt: "Feature Icon",
          slidersubTitle: "PT Protocols",
          sliderDesc:
            "Physical Therapy protocols that are pre-programmed for specific injuries, they can also be customized based on the circumstances.",
        },
        {
          imgSrc: "images/feature/icon/1-2-110x110.png",
          alt: "Feature Icon",
          slidersubTitle: "Message Your Coach",
          sliderDesc:
            "The messenger system allows you to have an online chat or consultation via video if needed. Normal response time is within 24 hours.",
        },
        {
          imgSrc: "images/feature/icon/1-3-110x110.png",
          alt: "Feature Icon",
          slidersubTitle: "Shop Recommended Equipment",
          sliderDesc:
            "The shop feature allows clients to search for therapy and exercise equipment recommended by therapists and coaches. It also has specific nutritional products that are used to help stay healthy.",
        },
        {
          imgSrc: "images/feature/icon/1-4-110x110.png",
          alt: "Feature Icon",
          slidersubTitle: "Workouts at a Glance",
          sliderDesc:
            "The program section allows for clients to see what workouts are ahead for the week. It is customizable or they can enroll in general training programs. Clients can keep track of the weight, sets, and reps that are used during the workout. This will help with feedback for coaches and personal tracking.",
        },
        /*{
          imgSrc: "images/feature/icon/1-4-110x110.png",
          alt: "Feature Icon",
          slidersubTitle: "Sign-up & Login",
          sliderDesc:
            "Users shall be allowed to sign up using their Facebook, Instagram, Google, Twitter, and their email id according to their preference.",
        },*/
        {
          imgSrc: "images/feature/icon/1-4-110x110.png",
          alt: "Feature Icon",
          slidersubTitle: "User Profiles",
          sliderDesc:
            "Personal information like name, age, height, gender, weight, and fitness level which is entered by the users. Having a personal profile makes it easier to track history of exercises you have completed.",
        },
        {
          imgSrc: "images/feature/icon/1-4-110x110.png",
          alt: "Feature Icon",
          slidersubTitle: "Workouts & Exercises",
          sliderDesc:
            "Both video Illustrations and descriptions are used. Users can plan their workouts, set of exercises or it can be a combination of both. The content library is organized and easily searchable.",
        },
        {
          imgSrc: "images/feature/icon/1-4-110x110.png",
          alt: "Feature Icon",
          slidersubTitle: "Push Notifications",
          sliderDesc:
            "The app will allow users to manage the timing of reminders, push notifications, and also to turn them off if not required.",
        },
      ],
    };
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
  },
};
</script>
