<template>
  <!-- Begin Banner Area -->
  <div class="banner-area banner-style-4">
    <div class="container banner-container">
      <div class="row">
        <div class="col-lg-5">
          <div class="banner-img">
            <img src="images/banner/phix-protocols-461x1001.png" alt="Banner" />
          </div>
        </div>
        <div class="col-lg-7 align-self-center">
          <div class="banner-content">
            <span class="banner-category">Physical Therapy</span>
            <h2 class="banner-title">
              At your fingertips with <span>PHIX.FIT</span>
            </h2>
            <p class="banner-desc">
              The plans developed by our PTs are great for managing pain,
              preventing future health hardships, restoring your mobility, and
              returning you to a high level of function.
            </p>
            <p class="banner-desc mb-0">
              Custom and general programs for therapy, training, prevention, and
              athletic development. Over 1000 custom videos and descriptions of
              exercises that are used commonly throughout the therapy and
              fitness industries.
            </p>
            <div class="btn-wrap">
              <a
                href="https://app.phix.fit"
                class="btn btn-custom-size extbot-primary-2 blackcurrant-hover"
                >Get Started Now</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Banner Area End Here -->
</template>
